import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Link } from 'gatsby'
import { getLocale, getLocaleUrl } from '../../utils/localeUtils'
import * as styles from '../../css/molecules/newsGridPreviewCard.module.scss'
import { Chips } from '../shared/molecules/Chips'
import { BioButton } from '../shared'
import classNames from 'classnames'
import { getGermanDateFromSanityDate } from '../../utils/getGermanDateFromSanityDate'

const ExternalLinkSwitch = ({
  classes,
  intern,
  children,
  extern = null,
  base = '/presse/'
}) => {
  const locale = process.env.GATSBY_CURRENT_BUILD

  if (!intern && !extern) return null
  return extern ? (
    <a
      href={extern}
      target="_blank"
      rel="noreferrer noopener"
      className={classes}>
      {children}
    </a>
  ) : intern ? (
    <Link to={'/' + getLocaleUrl(locale, intern)} className={classes}>
      {children}
    </Link>
  ) : null
}

/**
 *
 * @param category optional
 */
export const NewsGridPreviewCard = ({ post, classes, index }) => {
  const isExtern = post.externalUrl
  const date = getGermanDateFromSanityDate(
    post.newsMetaInfo?.updateDate || post.newsMetaInfo?.releaseDate
  )
  return (
    <ExternalLinkSwitch
      classes={classNames(styles.newsGridPreviewCard, classes, 'newCard')}
      key={post.title}
      intern={
        (post?.category?.parentCategory?.slug
          ? post?.category.parentCategory.slug + '/'
          : '') +
        post.category?.slug +
        '/' +
        post.slug
      }
      extern={post.externalUrl}>
      <div data-cy="newsCardImage" className={styles.__imageContainer}>
        {typeof post.previewImage !== 'string' ? (
          <BgImage
            image={getImage(post.previewImage?.image?.asset)}
            alt={post.previewImage?.alt}
            className={styles.__image}
            data-cy="previewImage"
          />
        ) : (
          <div
            data-cy="previewImage"
            style={{
              backgroundImage: `url(${post.previewImage})`
            }}
            className={styles.__image}></div>
        )}
        {/* {post.category && post.hasCategoryOnImage && (
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 px-12 pt-1 pb-2 text-md font-bold text-white z-10 bg-preview-cards-category bg-no-repeat bg-cover">
                  {post.category}
                </div>
              )} */}
      </div>
      <div
        className={classNames(
          styles.__textContainer,
          isExtern && styles.isExtern
        )}>
        <div className={styles.__textContent}>
          <span className={classNames(styles.__mediaInfo)}>
            <span
              className={classNames(
                styles.__location,
                isExtern && styles.isExtern
              )}>
              {post.newsMetaInfo?.source || post.newsMetaInfo?.location}
            </span>
            &nbsp;|&nbsp;
            <span className={styles.__date}>
              <span>{date}</span>
            </span>
          </span>
          <h3 className={styles.__title}>{post.title}</h3>
          <p className={styles.__description}>{post.desc}</p>
          <Chips chips={post.tags} />
        </div>
        <div className={styles.__buttonContainer}>
          <BioButton
            style="isSmall isHigh"
            // button={{ buttonIcon: 'LargeArrowRight', buttonCta: '' }}
            button={{
              buttonIcon: isExtern ? 'LinkIcon' : 'LargeArrowRight',
              buttonCta: ''
            }}
          />
        </div>
      </div>
    </ExternalLinkSwitch>
  )
}
