import React from 'react'
import { BioNoResults } from '../shared'

import * as styles from './../../css/molecules/newsGrid.module.scss'
import { NewsGridPreviewCard } from './NewsGridPreviewCard'
import classNames from 'classnames'
import { NewsTicker } from './NewsTicker'
import { useSearchStore } from '../../utils'

export const NewsGrid = ({
  items,
  noSearchResultsText,
  sanityMarkets,
  showNewsTicker,
  isPressemitteilungen
}) => {
  if (items.length === 0) return <BioNoResults content={noSearchResultsText} />
  const searchTerm = useSearchStore(state => state.searchTerm['news'])

  const filteredItems = isPressemitteilungen
    ? items.filter(post => !post.externalUrl)
    : items

  return (
    <div className={classNames(styles.newsGrid, 'newCards')}>
      {filteredItems.map((post, index) => {
        if (showNewsTicker && !searchTerm && index === 0) {
          return (
            <NewsTicker
              sanityMarkets={sanityMarkets}
              key="newsticker"
              classes={styles.cardSpecialPosition}
            />
          )
        }
        return (
          <NewsGridPreviewCard
            post={post}
            key={`post_${index}`}
            index={index}
            classes={classNames(styles.cardItem, styles[`cardItem${index}`])}
          />
        )
      })}
    </div>
  )
}
