// extracted by mini-css-extract-plugin
export var __divider = "newsTicker-module--__divider--c92ad";
export var __heading = "newsTicker-module--__heading--29ad6";
export var __item = "newsTicker-module--__item--4a61e";
export var __marketAddress = "newsTicker-module--__marketAddress--fef9d";
export var __marketDelayed = "newsTicker-module--__marketDelayed--d0076";
export var __marketDescription = "newsTicker-module--__marketDescription--acf35";
export var __marketImage = "newsTicker-module--__marketImage--b3bab";
export var __marketName = "newsTicker-module--__marketName--cdb3f";
export var __marketOpening = "newsTicker-module--__marketOpening--eb6fe";
export var __noOpening = "newsTicker-module--__noOpening--d3ae2";
export var newsTicker = "newsTicker-module--newsTicker--29e29";