import React from 'react'

import classNames from 'classnames'
import * as styles from './../../../css/molecules/chips.module.scss'

const Chip = ({ chip }) => {
  return <span className={styles.chip}>{chip.title}</span>
}

export const Chips = ({ chips }) => {
  if (typeof chips == 'undefined' || !chips || !chips.length) return null
  return (
    <div className={styles.chips}>
      {chips.map((chip, index) => {
        return <Chip chip={chip} key={`chip_${index}`} />
      })}
    </div>
  )
}
