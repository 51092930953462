import React from 'react'

export const ExclamationMarkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="exclamation mark">
      <path
        id="vector"
        d="M9.30685 9.74353C9.43926 8.15456 9.57167 7.22767 9.64524 5.78583C9.70409 4.60882 9.65995 3.7702 9.67466 2.66675H6.52616C6.52616 3.7702 6.46731 4.60882 6.52616 5.78583C6.59972 7.22767 6.73213 8.1562 6.86455 9.74353H9.30685ZM6.59972 11.9357C6.59972 12.7449 6.99696 13.3334 8.10041 13.3334C9.20386 13.3334 9.61581 12.7449 9.61581 11.9357C9.61581 11.1265 9.20386 10.538 8.10041 10.538C7.9054 10.5083 7.7062 10.523 7.51771 10.5812C7.32921 10.6394 7.15632 10.7394 7.01197 10.8739C6.86762 11.0083 6.75556 11.1737 6.68417 11.3575C6.61277 11.5414 6.5839 11.7391 6.59972 11.9357Z"
        fill="#E0431E"
      />
    </g>
  </svg>
)
