// extracted by mini-css-extract-plugin
export var __buttonContainer = "newsGridPreviewCard-module--__buttonContainer--d3c22";
export var __date = "newsGridPreviewCard-module--__date--56074";
export var __description = "newsGridPreviewCard-module--__description--39144";
export var __image = "newsGridPreviewCard-module--__image--b8394";
export var __imageContainer = "newsGridPreviewCard-module--__imageContainer--f1f50";
export var __location = "newsGridPreviewCard-module--__location--5f80d";
export var __mediaInfo = "newsGridPreviewCard-module--__mediaInfo--14d7d";
export var __textContainer = "newsGridPreviewCard-module--__textContainer--f994f";
export var __textContent = "newsGridPreviewCard-module--__textContent--7fab6";
export var __title = "newsGridPreviewCard-module--__title--c746a";
export var isExtern = "newsGridPreviewCard-module--isExtern--b6dd1";
export var newsGridPreviewCard = "newsGridPreviewCard-module--newsGridPreviewCard--b18e5";