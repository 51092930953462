// extracted by mini-css-extract-plugin
export var __buttonContainer = "newsGrid-module--__buttonContainer--29921";
export var __description = "newsGrid-module--__description--12c8b";
export var cardItem = "newsGrid-module--cardItem--72a23";
export var cardItem1 = "newsGrid-module--cardItem1--d2964";
export var cardItem2 = "newsGrid-module--cardItem2--6ac3e";
export var cardItem3 = "newsGrid-module--cardItem3--1d91a";
export var cardSpecialPosition = "newsGrid-module--cardSpecialPosition--c38b9";
export var newsGrid = "newsGrid-module--newsGrid--febf5";
export var newsGrid__category = "newsGrid-module--newsGrid__category--68011";
export var newsGrid__title = "newsGrid-module--newsGrid__title--0c313";